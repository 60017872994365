import axios from 'axios';
export const ContactUsApi = async contactData => {
  return await new Promise((resolve,reject) => {
    const formId= '889a9f90-41a5-4180-8150-78acbdb9f244';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":contactData.email
        },
        {
            "name": "firstname",
            "value": contactData.name
        },
        {
            "name": "message",
            "value": contactData.comments
        },
        {
            "name": "type_of_funding",
            "value": contactData.subject
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": contactData.GDRPolicy,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
