import { Container, Row, Col, Image } from "react-bootstrap"
import Pawan from '../../Images/Jagannadha Pawan.png'
import Raj from '../../Images/PawanRaj.png'
import MiliShrivastava from '../../Images/Mili Shrivastava.png'
const IpowerzTeam = () => {
    return (
        <section className="team-ipowerz">
           <Container>
               <Row className="">
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={Pawan} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://www.tjp.one/" target="_blank" rel="noreferrer">Dr T Jagannadha Pawan</a></div>
                           <div className="profile-profession">Co Founder</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={Raj} roundedCircle  />
                           </div>
                           <div className="profile-title"><a href="https://www.linkedin.com/in/rajapragada" target="_blank" rel="noreferrer">D Raja Rao</a></div>
                           <div className="profile-profession">Co Founder</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={MiliShrivastava} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://staffprofiles.bournemouth.ac.uk/display/mshrivastava" target="_blank" rel="noreferrer">Dr Mili Shrivastava</a></div>
                           <div className="profile-profession">NED</div>
                       </div>
                   </Col>
               </Row>
           </Container>
        </section>
    )
}
export default IpowerzTeam;