import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
import ServicesImages from '../../Images/Success_Stories_AIC Anantapur.png'
const CaseStudies = () => {
    return(
        <section className="case-studies">
            <Container>
                <Row>
                    <Col md="5" lg="5" sm="5">
                        <div className="idea-title-left">
                            <h4 className="idea-powerz-title"><span>Case Studies</span><br/>Success Stories</h4>
                        </div>
                    </Col>
                    <Col md="7" lg="7">
                        <div className="anchor-see-block">
                            <span className="anchor-see-all"></span>
                            <a href="/">See All</a>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:"60px"}}>
                    <Col md="6" lg="6" sm="6">
                       <div className="iframe">
                           <img width="100%" height="315" src={ServicesImages} title="Anatapur" style={{borderRadius: "10px"}} alt="Anatpur" />
                        </div>
                        <h6 className="case-title">AIC Anantapur</h6>
                        <p>iPowerz is fast-tracking the growth of start-ups at Atal Incubation Centre (AIC) in Anantapur</p>
                        <div className="case-know-more">
                            <a href="/" className="case-knowMore">Know More</a>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" className="col-span">
                       <div className="iframe">
                           <iframe width="100%" height="315" src="https://www.youtube.com/embed/WYNwWtNyQRU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <h6 className="case-title">Mijwan Welfare Society</h6>
                        <p>iPowerz is enabling rural entrepreneurship in India in partnership with Mijwan Welfare Society</p>
                        <div className="case-know-more">
                            <a href="/" className="case-knowMore">Know More</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default CaseStudies;