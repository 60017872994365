import { NavbarMenu, TopBar } from '../components/navbar'
import '../css/AboutUs.css'
import TeamSection from './AboutUs/TeamSection'
import NewsLetter from './Home/NewsLetter'
import Footer from './Home/Footer'
import ScrollTop from 'react-scrolltop-button';
import React, { useEffect, useState } from 'react'
import TermsConditionHeader from './TermsUse/TermsConditionsHeader'
import TermsConditionContent from './TermsUse/TermsConditionContentBlock'
export const TermsConditions = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('fixed-top')
    } else {
        setnavbarPosition('')
    }
    }
    return (
        <>
        <section className={navBarPosition}>
        <TopBar />
        <NavbarMenu activeName="privacy" />
        </section>
        <section className="aboutUs-bg">
        <section className="">
            <TermsConditionHeader />
            <TermsConditionContent />
        </section>
        </section>
        <TeamSection />
        <NewsLetter />
        <Footer />
        <ScrollTop 
        text="🠙"
        style={{ backgroundColor: "#1b1b45", border: 'none', paddingTop: "0px" ,paddingBottom: "0px", color: "#ffffff", fontSize: "32px" }}
        className="scroll-your-role"
        />
      </>
    )   
}