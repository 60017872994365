import axios from 'axios';
export const RequestApi = async scheduleIcon => {
  return await new Promise((resolve,reject) => {
    const formId= '9528d734-c0c0-4aa4-8dbf-e50ba5703d9a';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":scheduleIcon.email
        },
        {
            "name": "firstname",
            "value": scheduleIcon.name
        },
        {
            "name": "phone",
            "value": scheduleIcon.mobileNumber
        },
        {
            "name": "message",
            "value": scheduleIcon.visitPurpose
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": scheduleIcon.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
