import {AboutUsTitle} from '../components/AboutUs/AboutUsTopHeader'
import VisonMission from './AboutUs/VisionMission'
import { NavbarMenu, TopBar } from '../components/navbar'
import WhatWeDo from '../components/AboutUs/WhatWeDo'
import IpowerzFastTrack from '../components/AboutUs/IpowerzFastTrack'
import '../css/AboutUs.css'
import TeamSection from './AboutUs/TeamSection'
import NewsLetter from './Home/NewsLetter'
import Footer from './Home/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import WhatWeDoImage from '../Images/iPowerz-WhatWedo-new.jpg'
import ScrollTop from 'react-scrolltop-button';
import React, { useEffect, useState } from 'react'
const AboutUs = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('fixed-top')
    } else {
        setnavbarPosition('')
    }
    }
    return (
        <>
        <section className={navBarPosition}>
        <TopBar />
        <NavbarMenu activeName="about" />
        </section>
        <section className="aboutUs-bg">
        <section className="aboutUs">
            <AboutUsTitle />
        </section>
        <Container>
            <Row>
                <Col md="7" lg="7" sm="7">
                    <VisonMission />
                    <WhatWeDo />
                </Col>
                <Col md="5" lg="5" sm="5">
                    <div className="image mt-5">
                        <img src={WhatWeDoImage} alt="What We Do" title="What We Do" className="w-100" />
                    </div>
                </Col>
            </Row>
        </Container>
        <IpowerzFastTrack />
        </section>
        <TeamSection />
        <NewsLetter />
        <Footer />
        <ScrollTop 
        text="🠙"
        style={{ backgroundColor: "#f7560f", border: 'none', paddingTop: "0px" ,paddingBottom: "0px", color: "#ffffff", fontSize: "32px" }}
        className="scroll-your-role"
        />
      </>
    )
}
export default AboutUs;