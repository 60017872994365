import { Container } from 'react-bootstrap';
export const CareerTiltle = () => {
    return(
        <section className="aboutUsTitle">
            <Container>
                <div className="about-powerz-content">
                    <div className="about-title-center">
                        <h4 className="about-powerz-title">Careers</h4>
                    </div>
                </div>
                <p className="mt-5 text-center mb-5" style={{fontSize:"22px"}}>Part of the role of people who support these makers, doers, and dreamers who call themselves ‘entrepreneurs’ is to recognize the struggle, hustle, and courage they exhibit every day, and build a robust network to help them succeed. There’s nothing better to describe what we do here at iPowerz. Come be a part of this journey of satisfaction with us today.
                </p>
            </Container>
        </section>
    )
}