import { Row, Col, Container, Card } from 'react-bootstrap';
const CarrerContentBlock = () => {
    return (
        <section className="new-Section-block">
            <Container>
            <Row>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="3" lg="3" sm="3">
                    <Card className="news-cards">
                        <Card.Body>
                            <Card.Title>Career Title</Card.Title>
                            <Card.Text>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </Card.Text>
                            <Card.Link href="#" className="card-link-read">Apply Now</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CarrerContentBlock;