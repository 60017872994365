import '../css/main-v2.css'
import { NavbarMenu, TopBar } from '../components/navbar'
import { Banner } from './Home/Banner'
import WantFunds from './Home/WantFunds'
import MentorTabs from './Home/MentorsSection'
import IpowerzCards from './Home/IpowerzCards'
import Profiles from './Home/Profiles'
import CaseStudies from './Home/CaseStudies'
import BrandLogoSection from './Home/BrandLogo'
import NewsLetter from './Home/NewsLetter'
import Footer from './Home/Footer'
import OurServices from './Home/Services'
import WhyPowerz from './Home/WhyPowerz'
import LaunchIdeas from './Home/LaunchIdeas'
import ScrollTop from 'react-scrolltop-button';
import React, { useEffect, useState } from 'react'
export const IndexPage = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('fixed-top')
    } else {
        setnavbarPosition('')
    }
    }
    return(
        <>
        <section className={navBarPosition}>
        <TopBar />
            <NavbarMenu activeName="home"/>
        </section>
            <Banner />
            {/* <SearchForm />
            <section className="bg-gradient-circle-image">
            </section>
        */ }
        <section className="bg-gradient-image">
        <WhyPowerz />
        <LaunchIdeas />
        </section>
        <section className="bg-gradient">
        <WantFunds />
        </section>
        <section className="bg-gradient-image">
        <MentorTabs />
        <IpowerzCards />
        </section>
        { /* <IpowerzPropsers />
        */ }
        <OurServices />
        <Profiles />
        <CaseStudies />
        <BrandLogoSection />
        <NewsLetter />
        <Footer />
        <ScrollTop 
        text="🠙"
        style={{ backgroundColor: "#f7560f", border: 'none', paddingTop: "0px" ,paddingBottom: "0px", color: "#ffffff", fontSize: "32px" }}
        className="scroll-your-role"
        />
    </>
    )
}