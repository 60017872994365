import { Container, Row, Col , Card } from 'react-bootstrap'
import {React} from 'react';
import iPowerzConnect from '../../Images/iPowerz-Connect.png'
import iPowerzCreate from '../../Images/iPowerz-Create.png'
import iPowerzChange from '../../Images/iPowerz-Change.png'
const IpowerzCards = () => {
    return(
        <>
         <section className="ipowerz-cards">
             <Container>
                 <Row>
                    <Col md="4" lg="4" sm="4">
                        <Card>
                            <Card.Img variant="top" src={iPowerzConnect} />
                            <Card.Body>
                                <Card.Title>Connect</Card.Title>
                                <Card.Text>
                                Connect and collaborate to accelerate business growth
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4" lg="4" sm="4" className="col-spac">
                        <Card>
                            <Card.Img variant="top" src={iPowerzCreate} />
                            <Card.Body>
                                <Card.Title>Create</Card.Title>
                                <Card.Text>
                                    Create new products and services for the global marketplace
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4" lg="4" sm="4" className="col-spac">
                        <Card>
                            <Card.Img variant="top" src={iPowerzChange} />
                            <Card.Body>
                                <Card.Title>Change</Card.Title>
                                <Card.Text>
                                    Contribute to the world with your unique offerings
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                 </Row>
             </Container>
         </section>
        </>
    )
}
export default IpowerzCards;