import { Container } from 'react-bootstrap';
export const SupportTiltle = () => {
    return(
        <section className="aboutUsTitle">
            <Container>
                <div className="about-powerz-content">
                    <div className="about-title-center">
                        <h4 className="about-powerz-title">Support</h4>
                    </div>
                </div>
                <p className="mt-5 text-center" style={{fontSize:"22px"}}>Startups do not exist in a vacuum but are part of a broader business environment. Universities, Funding Organizations, Support Organizations, Research Organizations, Service Providers, Large corporations and what not. Different organizations to focus on every function of your specific development stages.
                            </p>
            </Container>
        </section>
    )
}