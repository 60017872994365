import { Container } from 'react-bootstrap';
export const AboutUsTitle = () => {
    return(
        <section className="aboutUsTitle">
            <Container>
                <div className="about-powerz-content">
                    <div className="about-title-center">
                        <h4 className="about-powerz-title">About Us</h4>
                    </div>
                </div>
            </Container>
        </section>
    )
}