import axios from 'axios';
export const raiseFund = async fundsData => {
  return await new Promise((resolve,reject) => {
    const fd = new FormData();
    fd.append('investin',fundsData.investin);
    fd.append('pServiceName',fundsData.pServiceName)
    const requriedFunds = `${fundsData.priceBase}. ${fundsData.requiredFunds}` 
    fd.append('requiredFunds',requriedFunds)
if(fundsData.uploadprojectDocument){
  fd.append('uploadprojectDocument', fundsData.uploadprojectDocument, fundsData.uploadprojectDocument.name);
}
axios.post('https://dotwebideas.com/development/ipowerzApis/raiseFund.php', fd
    ).then(res => {
        resolve(res);
        
      
      })
      .catch(error => {
            reject(error);
        }
        );
  })
  
}

export const needFunds = async needsData => {
    return await new Promise((resolve,reject) => {
        const fd = new FormData();
        fd.append('fundName',needsData.fundName);
        fd.append('fundingType',needsData.fundingType);
        const requriedFunds = `${needsData.priceBase}. ${needsData.requiredFunds}`
        fd.append('fundingRequired',requriedFunds);
        fd.append('proccessEvalution',needsData.proccessEvalution);
        if(needsData.uploadDocuments) {
            fd.append('uploadDocuments',needsData.uploadDocuments,needsData.uploadDocuments.name);
        }
        axios.post('https://dotwebideas.com/development/ipowerzApis/needFunds.php', fd).then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        })
    })
}

