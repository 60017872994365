import { Row, Col } from 'react-bootstrap';
const VisonMission = () => {
    return (
        <section className="visionMission mt-5">
            <Row>
                    <Col md="12" lg="12">
                        <div className="aboutUs-title mt-3">Mission</div>
                        <p className="mt-3">Democratise capitalism to create prosperity for all.</p>
                    </Col>
                </Row>
                <Row className="second-mission-block mt-4">
                    <Col md="12" lg="12">
                        <div className="aboutUs-title">Vision</div>
                        <p className="mt-3">We believe the world needs more people to flourish. With the top 1% of start-ups
                            getting greatest attention and investment, the remaining 99% rarely get investment and support. iPowerz wants to make a difference by engaging and nurturing entrepreneurship and innovation of the 99% to create prosperity across the
                            board.</p>
                    </Col>
                </Row>
        </section>
    )

}
export default VisonMission;