import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    window.scrollTo(0,0)
  }
  componentDidMount() {
      window.scrollTo(
        {
          top:0,
          left:0,
        behavior: 'smooth'
        }
      )
  }
  componentWillUnmount(prevProps) {
      
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);