import { Row, Col, Container, Card } from 'react-bootstrap';
const NewsSection = () => {
    return (
        <section className="new-Section-block" style={{marginTop:"70px"}}>
            <Container>
                <Row>

                    <Col md="3" lg="3" sm="3">
                        <Card className="news-cards">
                            <Card.Body>
                                <Card.Title>August, 2021</Card.Title>
                                <Card.Text>
                                    iPowerz and Chotabeta come together to support MSMEs in India.
                                </Card.Text>
                                <Card.Link href="https://www.chotabeta.com/" target="_blank" className="card-link-read">Read More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card className="news-cards">
                            <Card.Body>
                                <Card.Title>July, 2021</Card.Title>
                                <Card.Text>
                                    iPowerz partners with Mijwan Welfare Society to support and accelerate innovation and entrepreneurship in rural India.
                                </Card.Text>
                                <Card.Link href="https://www.southampton.ac.uk/indiacentre/news/2021/06/17-ipowerz.page" target="_blank" className="card-link-read">Read More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card className="news-cards">
                            <Card.Body>
                                <Card.Title>June, 2021</Card.Title>
                                <Card.Text>
                                    World renowned entrepreneurship expert Prof David Audretsch joins the iPowerz advisory board.
                                </Card.Text>
                                <Card.Link href="https://oneill.indiana.edu/faculty-research/directory/profiles/faculty/full-time/audretsch-david.html" target="_blank" className="card-link-read">Read More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card className="news-cards">
                            <Card.Body>
                                <Card.Title>May, 2021</Card.Title>
                                <Card.Text>
                                    iPowerz and Atal Incubation Centre Anantapur launch a new partnership to support start-ups, scale-ups, micro and small businesses in Andhra Pradesh, India.
                                </Card.Text>
                                <Card.Link href="http://aic-sku.com/" target="_blank" className="card-link-read">Read More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card className="news-cards">
                            <Card.Body>
                                <Card.Title>April, 2021</Card.Title>
                                <Card.Text>
                                    iPowerz has joined the Council for Inclusive Capitalism, a movement of the world’s business and public sector leaders who are working to build a more inclusive, sustainable, and trusted economic system.
                                </Card.Text>
                                <Card.Link href="https://www.inclusivecapitalism.com/organization/ipowerz/" target="_blank" className="card-link-read">Read More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NewsSection;