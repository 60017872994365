import { Row, Col, Button, Form, Alert } from 'react-bootstrap'
import {React, useEffect, useState} from 'react';
import { validation } from '../Validation/Validation'
import { WantFundApi } from '../HubspotApis/WantFundApi' 

export const NeedFundForm = () => {
    const [fundsData,setFundsData] = useState({email:'',investin:'',pServiceName:'',uploadedFileUrl:'',requiredFunds:0,uploadprojectDocument:null, priceBase: 'USD', uploadedFileName:null, uploadFileObj:null, website: '',GDPCompalince:false})
    // eslint-disable-next-line
    { /* const uploadProjectDoc = e =>{
        e.preventDefault();
        setFundsData({...fundsData,uploadprojectDocument:e.target.files[0]})
        setNeedsFunds({...fundsData,uploadedFileName:e.target.files[0].name})
        setNeedsFunds({...fundsData,uploadFileObj:URL.createObjectURL(e.target.files[0])})
    } */ }
    const [fundDisableSubmt,setFundDisableSubmt]=useState(true)
    useEffect(()=>{
        if(fundsData.GDPCompalince){
                setFundDisableSubmt(false)
            }
            else {
                setFundDisableSubmt(true)
            }
    },[fundsData.GDPCompalince])
    const [fundsDataMessage, setFundsDataSuccessMessage]=useState(false)
    const needFund = async e => {
        e.preventDefault()
        const validationForm = validation(fundsData)
        const noErrors = Object.keys(validationForm).length===0
        if(noErrors){
            WantFundApi(fundsData).then((res)=>{
                setFundsDataSuccessMessage(true)
                setFundsData({
                    ...fundsData,
                    email:'',
                    investin:'',
                    pServiceName:'',
                    uploadedFileUrl:'',
                    requiredFunds:0,
                    uploadprojectDocument:null, 
                    priceBase: 'USD',
                    uploadedFileName:null,
                    uploadFileObj:null,
                     website: '',
                     GDPCompalince:false
                })
            }).catch(error=>{
                console.log(error)
            })
        }   
        else {
            alert(validationForm)
        }
    }
    return (
        <Form onSubmit={needFund} noValidate>
            <Row>
                <Col sm={6}>
                    <Form.Group controlId="formBasicEmail" className="mb-3 mt-3">
                        <Form.Label>I need funds for my</Form.Label>
                        <Form.Control type="text" placeholder="Business/ Not Profit: Name" onChange={e=>setFundsData({...fundsData,investin:e.target.value})} value={fundsData.investin}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group controlId="formBasicEmail" className="mb-3 mt-3">
                        <Form.Label>My email</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" onChange={e=>setFundsData({...fundsData,email:e.target.value})} value={fundsData.email}/>
                    </Form.Group>
                </Col>   
            </Row>
            <Row>
                <Col sm={6}>
                    <Form.Group controlId="formBasicPassword" className="mb-3 mt-3">
                        <Form.Label>My products / services</Form.Label>
                        <Form.Control type="text" placeholder="Describe products / services" onChange={e=>setFundsData({...fundsData,pServiceName:e.target.value})} value={fundsData.pServiceName} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Fund amount</Form.Label>
                        <Row>
                            <Col md="4" lg="4" sm="4" xs="4" className="no-padding-right">
                                <Form.Select className="form-control ruppe-symbol" onChange={e=>setFundsData({...fundsData,priceBase:e.target.value})} value={fundsData.priceBase}>
                                    <option>INR</option>
                                    <option>USD</option>
                                    <option>EUR</option>
                                    <option>GBP</option>
                                </Form.Select>
                            </Col>
                            <Col md="8" lg="8" m="8" xs="8" className="padding-left-none">
                                <Form.Control type="number" className="border-left-radius-none" onChange={e=>setFundsData({...fundsData,requiredFunds:e.target.value})} value={fundsData.requiredFunds}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="Website / Linked in / Twitter / Other"  onChange={e=>setFundsData({...fundsData,website:e.target.value})} value={fundsData.website}/>
                    </Form.Group>
                </Col>
                { /* <Col sm={6}>
                        <Form.Group className="mb-3 mt-3">
                        <Form.Label>Fund Profile /Details</Form.Label>
                        <div className="span-file-picker-btn">
                            <Form.Control type="file"  
                                id="custom-file-translate-html"
                                label="Upload(PDF, DOC, etc)"
                                // data-browse="Bestand kiezen
                                className="custom-file-label"
                                onChange={uploadProjectDoc}
                                custom />
                                <span>Upload(PDF, DOC, etc)</span>
                        </div>    </Form.Group> 
                    
                </Col>
                
                    fundsData.uploadedFileName ?
                    <span className="fileName" style={{marginTop:"10px",color:"#333"}}>{fundsData.uploadedFileName}</span>
                    : null
                    */
                } 
                { /* fundsData.uploadedFileName */ }
            </Row>
            <Col sm={12}>
                <span className="complaince-text-p">iPowerz is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</span>
                <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="I agree to receive other communications from iPowerz." onChange={e=>setFundsData({...fundsData,GDPCompalince:e.target.checked})} value={fundsData.GDPCompalince} required/>
                </Form.Group>
                <span className="complaince-text-p">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</span>
                <span className="complaince-text-p">By clicking submit below, you consent to allow iPowerz to store and process the personal information submitted above to provide you the content requested.</span>
            </Col>
            { /* <div className="text-center" style={{marginTop:"30px"}}>
                
            </div> */}
            <div className="text-center" style={{marginTop:"30px"}}>
                <Button type="submit" className="submitButton" disabled={fundDisableSubmt} style={{marginTop: "30px",paddingTop: "13px",paddingBottom:"13px"}}>Submit</Button>
            </div>
            <Alert
                variant="success"
                className={fundsDataMessage ? "d-block mt-3 mb-0" : "d-none"}
                >
                Thanks for contacting us. our team will get back to you soon
            </Alert>
            </Form>
    )
}