import { Row, Col, Button, Form , Alert } from 'react-bootstrap'
import {React, useState, useEffect} from 'react';
import { MentorsValidation } from '../Validation/Validation';
import { EcoBuilderApi } from '../HubspotApis/EcoBuilderApi'

export const EcoSystemForm = () => {
    const [ecoSystem,setEcoSystemBuilder] = useState({email:'',mobileNumber:'',name:'',organizationType:'',website:'',GDPCompalince:false})
    useEffect(()=>{
        if(ecoSystem.GDPCompalince){
            setDisableSubmit(false)
        }
        else {
            setDisableSubmit(true)
        }
    },[ecoSystem.GDPCompalince])
    const [disableSubmit,setDisableSubmit] = useState(true)
    const [ecoSystemSuccessMessage,setEcoSystemSuccessMessage]=useState(false)
    const ecoSystemSubmit = e => {
        e.preventDefault()
        const validationForm = MentorsValidation(ecoSystem)
        const noErrors = Object.keys(validationForm).length===0
        if(noErrors) {
            EcoBuilderApi(ecoSystem).then((res)=>{
                setEcoSystemSuccessMessage(true)
                setEcoSystemBuilder({
                    ...ecoSystem,
                    name: '',
                    email: '',
                    website: '',
                    mobileNumber:Number,
                    organizationType: ''
                })
            })
        }
        else {
            alert(validationForm)
        }
    }
    return (
        <Form onSubmit={ecoSystemSubmit} noValidate>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name"  onChange={e=>setEcoSystemBuilder({...ecoSystem,name:e.target.value})} value={ecoSystem.name} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control type="number" placeholder="Enter Mobile Number" onChange={e=>setEcoSystemBuilder({...ecoSystem,mobileNumber:e.target.value})} value={ecoSystem.mobileNumber} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" placeholder="Please Enter Email" onChange={e=>setEcoSystemBuilder({...ecoSystem,email:e.target.value})} value={ecoSystem.email} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Type of organizations</Form.Label>
                        <Form.Control type="text" placeholder="Business Incubator / Accelerator / Business Association / University / Non Profit / Other" onChange={e=>setEcoSystemBuilder({...ecoSystem,organizationType:e.target.value})} value={ecoSystem.organizationType} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="url" placeholder="Website / Linked in / Twitter / Other" onChange={e=>setEcoSystemBuilder({...ecoSystem,website:e.target.value})} value={ecoSystem.website} />
                    </Form.Group>
                </Col>
            </Row>
            <Col sm={12}>
                <span className="complaince-text-p">iPowerz is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</span>
                <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="I agree to receive other communications from iPowerz." onChange={e=>setEcoSystemBuilder({...ecoSystem,GDPCompalince:e.target.checked})} value={ecoSystem.GDPCompalince} required/>
                </Form.Group>
                <span className="complaince-text-p">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</span>
                <span className="complaince-text-p">By clicking submit below, you consent to allow iPowerz to store and process the personal information submitted above to provide you the content requested.</span>
            </Col>
            <div className="text-center">
                <Button type="submit" className="submitButton" style={{marginTop: "30px",paddingTop: "13px",paddingBottom:"13px"}} disabled={disableSubmit}>Submit</Button>
            </div>
            <Alert
                variant="success"
                className={ecoSystemSuccessMessage ? "d-block mt-3 mb-0" : "d-none"}
                >
                Thanks for contacting us. our team will get back to you soon
            </Alert>
        </Form>
    )
}