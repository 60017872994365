import { Container, Row, Col, Card } from 'react-bootstrap'
import {React} from 'react';
import UserProfile from '../../Images/userProfile.svg'
import DocumentsUpload from '../../Images/DocumentsUpload.svg'
import VerificationValidation from '../../Images/Verification&Validation.svg'
import AIandBIAnalytics from '../../Images/AI and BI Analytics.svg'
import AccessingGlobalMarkets from '../../Images/Accessing Global Markets.svg'
import Financially from '../../Images/Financial&Non-Financial Services.svg'
import CommunicationServices from '../../Images/Communication Services.svg'
import TrackingMonitoringServices from '../../Images/Tracking & Monitoring Services.svg'
const Profiles = () => {
    return (
        <section className="profiles-block">
            <Container>
                <Row>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                                <div className="profile-icons">
                                    <img src={UserProfile} alt="Profile" />
                                </div>
                                <Card.Title>User Profiles</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                               <div className="profile-icons">
                                    <img src={DocumentsUpload} alt="Profile" />
                                </div>
                                <Card.Title>Document Upload</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                                <div className="profile-icons">
                                    <img src={VerificationValidation} alt="Profile" />
                                </div>
                                <Card.Title>Verification & Validation</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                               <div className="profile-icons">
                                    <img src={AIandBIAnalytics} alt="Profile" />
                                </div>
                                <Card.Title>AI and BI Analytics</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                                <div className="profile-icons">
                                    <img src={CommunicationServices} alt="Profile" />
                                </div>
                                <Card.Title>Communication Services</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                               <div className="profile-icons">
                                    <img src={Financially} alt="Profile" />
                                </div>
                                <Card.Title>Financial & Non-Financial Services</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                                <div className="profile-icons">
                                    <img src={AccessingGlobalMarkets} alt="Profile" />
                                </div>
                                <Card.Title>Accessing Global Markets</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3">
                        <Card>
                            <Card.Body>
                                <div className="profile-icons">
                                    <img src={TrackingMonitoringServices} alt="Profile" />
                                </div>
                                <Card.Title>Tracking & Monitoring Services</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Profiles;