import axios from 'axios';
export const NeedFundsAPi = async needFunds => {
  return await new Promise((resolve,reject) => {
    const formId= '28fed709-5e89-402e-bd14-153025c6cbcd';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":needFunds.email
        },
        {
            "name": "firstname",
            "value": needFunds.fundName
        },
        {
            "name": "type_of_funding",
            "value": needFunds.fundingType
        },
        {
            "name": "fund_amount",
            "value": needFunds.minFundAmount
        },
        {
            "name": "name_of_the_fund",
            "value": needFunds.maxFundAmount
        },
        {
            "name": "message",
            "value": needFunds.proccessEvalution
        },
        {
            "name": "website",
            "value": needFunds.website
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": needFunds.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
