import validator from 'validator'
export const validation = values =>{
    const errors={}
    if (!validator.isEmail(values.email)) {
        return errors.email = 'Please Enter Valid Email id'
    }
    return errors;
    
}
export const needFundValidation = values => {
    const errors={}
    if (!validator.isEmail(values.email)) {
        return errors.email = 'Please Enter Valid Email id'
    }
    return errors;
}
export const MentorsValidation = values => {
    const errors={}
    if(!values.email){
        return errors.email = 'Please Enter Email id'
    }
    else if (!validator.isEmail(values.email)) {
        return errors.email = 'Please Enter Valid Email id'
    }
    return errors;
}