import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
import Southampton from '../../Images/Southampton.png'
import CouncilLogo from '../../Images/new-CouncilLogo_color.png'
import IBG from '../../Images/new-IBG.png'
import iii from '../../Images/new-iii-logo.png'
import Chotabeta from '../../Images/Chotabeta.png'
import AIcAnatapur from '../../Images/AIcAnatapur.png'
import Mjwan from '../../Images/Mjwan.png'
import Futureworlds from '../../Images/Futureworlds.png'
const BrandLogoSection = () => {
    return(
        <section className="brand-block">
            <Container>
                <Row>
                    <Col md="8" lg="8" sm="8">
                        <div className="idea-title-left mobile-service-left">
                            <h4 className="idea-powerz-title"><span>iPowerz</span><br/>Trusted By</h4>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4">
                        <div className="anchor-see-block">
                            <span className="anchor-see-all"></span>
                            <a href={`${process.env.PUBLIC_URL}/`}>See All</a>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:"60px"}}>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                           <img src={Southampton} alt="Southampton" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                           <img src={CouncilLogo} alt="CouncilLogo" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                           <img src={IBG} alt="IBG" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                           <img src={iii} alt="iii" />
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:"60px"}}>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                            <img src={Futureworlds} alt="Futureworlds" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                            <img src={AIcAnatapur} alt="AIcAnatapur" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                            <img src={Mjwan} alt="Mjwan" />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="4">
                        <div className="brand-logo">
                            <img src={Chotabeta} alt="Chotabeta" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default BrandLogoSection;