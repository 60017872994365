import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
const WhyPowerz = () => {
    return(
        <section className="why-powerz">
            <Container>
                <Row className="justify-center">
                    <Col md="12" lg="12">
                        <Row>
                            <Col md="3" lg="3" className="self-center">
                                <div className="ipowerz-title-block mobile-after-block">
                                  <h4 className="ipowerz-title"><span>Why</span><br/>iPowerz</h4>
                                </div>
                            </Col>
                            <Col md="9" lg="9" className="border-left-seperator">
                                <div className="whyipowerz-content">
                                    <p>Join iPowerz to connect with funders, investors,
                                    mentors and professional service providers.
                                    Showcase products to customers worldwide.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default WhyPowerz;