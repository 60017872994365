import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
import FooterLogo from '../../Images/iPowerz-Logo-01.png'
/* import Facebook from '../../Images/facebook.svg'
import Instagram from '../../Images/instagram.svg'
*/
import Twitter from '../../Images/twitter.svg'
import Youtube from '../../Images/youtube.svg'
import LinkedIn from '../../Images/linkedin.png'
export const Footer = () => {
    return(
        <section className="footer-part">
            <Container>
                <Row>
                    <Col md="4" lg="4" sm="4">
                       <div className="footer-block">
                           <img src={FooterLogo} alt="Footer logo" title="Footer Logo" className="footer-logo" />
                               <p>“iPowerz is the global digital ecosystem that enables entrepreneurship and innovation for the 99%."</p>
                            
                        </div>
                    </Col>
                    <Col md="5" lg="5" sm="5">
                       <h4 className="footer-title">Menu</h4>
                       <Row>
                           <Col md="6" lg="6" sm="6" className="col-span">
                               <a href="#/about">About</a>
                               <a href="#/services">Services</a>
                               { /* <a href="#/investors">Investors</a>*/ }
                               <a href="#/media">Press & Media</a>
                           </Col>
                           <Col md="6" lg="6" sm="6" className="col-span">
                                <a href="#/blog">Blog</a>
                               { /* <a href="#/support">Support</a>*/ }
                               <a href="#/contact">Contact</a>
                               <a href="#/team">Team</a>
                           </Col>
                       </Row>
                    </Col>
                    <Col md="3" lg="3" sm="3" className="self-center">
                        <div className="footer-block">
                            <ul className="social-icons">
                                <li><a href="https://www.linkedin.com/company/ipowerz/" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="" /></a> </li>
                                <li><a href="https://twitter.com/ipowerzcom" target="_blank" rel="noreferrer"><img src={Twitter} alt="" /></a></li>
                                <li><a href="https://www.youtube.com/channel/UCCb6JlLFcoXRFjJfkh_2AEA" target="_blank" rel="noreferrer"><img src={Youtube} alt="" /></a></li>
                            </ul>
                            <p>For any queries <br/>
                            Email: <span>support@ipowerz.com</span></p>
                        </div>
                    </Col>
                </Row>
                <section className="copyright">
                    <Container>
                        <Row>
                            <Col md="12" lg="12" sm="12">
                                <h6 className="copy-right-title">Copyright © 2021 <span><a href={`${process.env.PUBLIC_URL}/`} style={{color:'#f7560f'}} rel="noreferrer">iPowerz.com.</a></span> All Rights Reserved. <a href="#/terms">Terms of Use</a> | <a href="#/privacy">Privacy Policy</a></h6>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>
        </section>
    )
}
export default Footer;