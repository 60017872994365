import { Row, Col, Button, Form, Alert } from 'react-bootstrap'
import {React, useEffect, useState} from 'react';
import { needFundValidation } from '../Validation/Validation'
import {needFunds} from '../Apis/FormSubmission'
import { NeedFundsAPi } from '../HubspotApis/NeedFundApi'
export const FundProjectForm = () => {
    const [fundDisableSubmt,setFundDisableSubmt]=useState(true)
    const [needFundSuccessMessage,setneedFundSuccessMessage]=useState(false)
    const [needsFunds,setNeedsFunds] = useState({email:'',fundName: '' ,fundingType:'',uploadedFileUrl:'',fundingRequired: 0 ,proccessEvalution:'',uploadDocuments:null,uploadedFileName:null, priceBase: 'USD', uploadFileObj:null, website: '',GDPCompalince:false,minFundAmount:0,maxFundAmount:0})
    useEffect(()=>{
        if(needsFunds.GDPCompalince){
                setFundDisableSubmt(false)
                console.log('checked')
            }
            else {
                setFundDisableSubmt(true)
            }
    },[needsFunds.GDPCompalince])
    // eslint-disable-next-line
    { /* const needFundDocuments = e =>{
        e.preventDefault();
        // eslint-disable-next-line
        var file = e.target.files[0];
        setNeedsFunds({...needsFunds,uploadDocuments:e.target.files[0]})
        setNeedsFunds({...needsFunds,uploadedFileName:e.target.files[0].name})
        setNeedsFunds({...needsFunds,uploadFileObj:URL.createObjectURL(e.target.files[0])})   
    } */}
    const FundNeeds = async e => {
        e.preventDefault()
        const validationForm = needFundValidation(needsFunds)
        const noErrors = Object.keys(validationForm).length===0
        if(noErrors){
            // eslint-disable-next-line no-lone-blocks
            {/* }
            raiseFund(needsFunds).then((res)=>{
                console.log(res)
                if(res.data.success){
                    setNeedsFunds({...needFunds,uploadedFileUrl:res.data.documentUploaded})
                    
                }
            }) */ }
            NeedFundsAPi(needsFunds).then((res)=>{
                setneedFundSuccessMessage(true)
                setNeedsFunds({
                    ...needFunds,
                    email:'',
                    fundName: '' ,
                    fundingType:'',
                    uploadedFileUrl:'',
                    fundingRequired: 0 ,
                    proccessEvalution:'',
                    uploadDocuments:null,
                    uploadedFileName:null,
                     priceBase: 'USD', 
                     uploadFileObj:null,
                      website: '',
                      GDPCompalince:false,
                    minFundAmount:0,
                    maxFundAmount:0

                })
            }).catch(error=>{
                console.log(error)
            })
        }   
        else {
            alert(validationForm)
        }
        /* const validationForm = needFundValidation(needsFunds)
        const noErrors = Object.keys(validationForm).length===0
        if(noErrors){
           
            /* raiseFund(needsFunds).then((res)=>{
                console.log(res)
                if(res.data.success){
                    alert('Your Request is Received Our Team Will Get Back to You Soon')
                    window.location.reload()
                }
            }) 
        }   
        else {
            alert(validationForm)
        } */
        console.log(needsFunds.fundName)
    }
    return (
        <Form onSubmit={FundNeeds} noValidate>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Funder / Fund / Angel / VC / Bank" onChange={e=>setNeedsFunds({...needsFunds,fundName:e.target.value})} value={needsFunds.fundName} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" onChange={e=>setNeedsFunds({...needsFunds,email:e.target.value})} value={needsFunds.email} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                <Form.Group controlId="formBasicPassword" className="mb-3 mt-3">
                    <Form.Label>Type of Funding</Form.Label>
                    <Form.Control type="text" placeholder="Loan / Private Equity / Grant / Donation" onChange={e=>setNeedsFunds({...needsFunds,fundingType:e.target.value})} value={needsFunds.fundingType}/>
                </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="Website / Linked in / Twitter / Other" onChange={e=>setNeedsFunds({...needsFunds,website:e.target.value})} value={needsFunds.website} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Minimum Fund Amount</Form.Label>
                        <Row>
                            <Col md="4" lg="4" sm="4" xs="4" className="no-padding-right">
                                <Form.Select className="form-control ruppe-symbol" onChange={e=>setNeedsFunds({...needsFunds,priceBase:e.target.value})} value={needsFunds.priceBase}>
                                    <option>INR</option>
                                    <option>USD</option>
                                    <option>EUR</option>
                                    <option>GBP</option>
                                </Form.Select>
                            </Col>
                            <Col md="8" lg="8" m="8" xs="8" className="padding-left-none">
                                <Form.Control type="number" className="border-left-radius-none" onChange={e=>setNeedsFunds({...needsFunds,minFundAmount:e.target.value})} value={needsFunds.minFundAmount}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Maximum Fund Amount</Form.Label>
                        <Row>
                            <Col md="4" lg="4" sm="4" xs="4" className="no-padding-right">
                                <Form.Select className="form-control ruppe-symbol" onChange={e=>setNeedsFunds({...needsFunds,priceBase:e.target.value})} value={needsFunds.priceBase}>
                                    <option>INR</option>
                                    <option>USD</option>
                                    <option>EUR</option>
                                    <option>GBP</option>
                                </Form.Select>
                            </Col>
                            <Col md="8" lg="8" m="8" xs="8" className="padding-left-none">
                                <Form.Control type="number" className="border-left-radius-none" onChange={e=>setNeedsFunds({...needsFunds,maxFundAmount:e.target.value})} value={needsFunds.maxFundAmount}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3 text-area mt-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>More information</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={e=>setNeedsFunds({...needsFunds,proccessEvalution:e.target.value})} value={needsFunds.proccessEvalution} placeholder="Optional" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                { /* <Col sm={6}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Fund Profile /Details</Form.Label>
                        <div className="span-file-picker-btn">
                            <Form.Control type="file"  
                                id="custom-file-translate-html"
                                label="Upload(PDF, DOC, etc)"
                                onChange={needFundDocuments}
                                // data-browse="Bestand kiezen
                                className="custom-file-label"
                                custom />
                                <span>Upload(PDF, DOC, etc)</span>
                        </div>
                    </Form.Group>
                    {
                        needsFunds.uploadedFileName ?
                        <span className="fileName" style={{marginTop:"10px",color:"#333"}}>{needsFunds.uploadedFileName}</span>
                        : null
                    }
                </Col> */}
                <Col sm={12}>
                    <span className="complaince-text-p">iPowerz is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</span>
                    <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I agree to receive other communications from iPowerz." onChange={e=>setNeedsFunds({...needsFunds,GDPCompalince:e.target.checked})} value={needsFunds.GDPCompalince} required/>
                    </Form.Group>
                    <span className="complaince-text-p">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</span>
                    <span className="complaince-text-p">By clicking submit below, you consent to allow iPowerz to store and process the personal information submitted above to provide you the content requested.</span>
                </Col>
                <div className="text-center" style={{marginTop:"30px"}}>
                    <Button type="submit" className="submitButton" style={{}} disabled={fundDisableSubmt}>Submit</Button>
                </div>
                <Alert
                    variant="success"
                    className={needFundSuccessMessage ? "d-block mt-3 mb-0" : "d-none"}
                    >
                    Thanks for contacting us. our team will get back to you soon
                </Alert>
            </Row>
        </Form>
    )
}