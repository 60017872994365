import { Row, Col, Container } from 'react-bootstrap';
const FundraisingTips = () => {
    return (
        <section className="visionMission mt-5 mb-5">
            <Container>
                <Row>
                    <Col md="12" lg="12">
                        <div class="aboutUs-title mt-3">Fundraising Tips</div>
                        <p className="mt-3 mb-3">Money is the bloodline of any business. The long painstaking yet exciting journey from the idea to revenue generating business needs a fuel named capital. That’s why, at almost every stage of the business, entrepreneurs find themselves asking – How do I finance my startup?</p>
                        <ul className="bullet-tips-list mb-5">
                            <li>
                                <p><strong>Bootstrapping your startup business:</strong> Self-funding, also known as bootstrapping, is an effective way of startup financing, especially when you are just starting your business. </p>
                            </li>
                            <li>
                                <p><strong>Crowdfunding As A Funding Option:</strong> Crowdfunding is one of the newer ways of funding a startup that has been gaining lot of popularity lately. It’s like taking a loan, pre-order, contribution or investments from more than one person at the same time.</p>
                            </li>
                            <li>
                                <p><strong>Get Angel Investment In Your Startup:</strong> Angel investors are individuals with surplus cash and a keen interest to invest in upcoming startups. They also work in groups of networks to collectively screen the proposals before investing. They can also offer mentoring or advice alongside capital.</p>
                            </li>
                            <li>
                                <p><strong>Get Venture Capital For Your Business:</strong> This is where you make the big bets. Venture capitals are professionally managed funds who invest in companies that have huge potential. VCs provide expertise, mentorship and acts as a litmus test of where the organisation is going, evaluating the business from the sustainability and scalability point of view.</p>
                            </li>
                            <li>
                                <p><strong>Get Funding From Business Incubators & Accelerators:</strong> Early stage businesses can consider Incubator and Accelerator programs as a funding option. Found in almost every major city, these programs assist hundreds of startup businesses every year.</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
export default FundraisingTips;