import { Container } from 'react-bootstrap';
const MediaHeader = () => {
    return(
        <section className="Team-title">
            <Container>
                <div className="about-powerz-content">
                    <div className="about-title-center">
                        <h4 className="about-powerz-title">Press & Media</h4>
                    </div>
                </div>
                <p className="mt-5 text-center" style={{fontSize:"22px"}}>The need for a good reputation should never be underestimated. Our reputation is our one constant; the thing we can rely on to meet every challenge.
                </p>
            </Container>
        </section>
    )
}
export default MediaHeader;