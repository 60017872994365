import axios from 'axios';
export const ScheduleApi = async scheduleData => {
  return await new Promise((resolve,reject) => {
    const formId= '8a8d1b2c-24cf-4343-8c36-7d9957e2fa96';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":scheduleData.email
        },
        {
            "name": "firstname",
            "value": scheduleData.name
        },
        {
            "name": "phone",
            "value": scheduleData.mobileNumber
        },
        {
            "name": "message",
            "value": scheduleData.proposedTime
        },
        {
            "name": "process_of_evaluation",
            "value": scheduleData.proposedDate
        },
        {
            "name": "type_of_funding",
            "value": scheduleData.iam
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": scheduleData.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
