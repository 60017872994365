import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import {React} from 'react';
import Mentors from '../../Images/mentor.png'
import Professionals from '../../Images/Professionals.png'
import Ecosystem from '../../Images/ecosystem.png'
import { MentorsForm } from '../Forms/MentorForm';
import { ProfessionalsForm } from '../Forms/ProfessionalsForm';
import { EcoSystemForm } from '../Forms/EcoSystemForm';
import { MarketPlaceForm } from '../Forms/MarketPlaceForm';
import Marketplace from '../../Images/Marketplace.png'

const MentorTabs = () => {
   
    return(
        <section className="mentorTab">
            <Container>
                <div className="idea-powerz-content">
                    {/* <div className="idea-title-left">
                        <h4 className="idea-powerz-title"><span>Global</span><br/>Digital Ecosystem</h4>
    </div> */}
                    <p>Join to connect and accelerate business growth</p>
                </div>
                <Row style={{marginTop:"30px"}} className="mentor-funds">
                    <Col md="12" lg="12">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="mentors">
                    <Col style={{width:"100%"}} className="col-12">
                        <Nav variant="pills" className="flex-column" style={{width:"100%"}}>
                            <Row>
                                <Col md="3" lg="3" sm="3" className="col-6">
                                    <Nav.Item>
                                        <Nav.Link eventKey="mentors">
                                            <div className="image-icon">
                                                <img src={Mentors} alt="Mentors" title="Mentors" />
                                                <div>Mentors</div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col md="3" lg="3" sm="3" className="col-6">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Professionals">
                                            <div className="image-icon">
                                                <img src={Professionals} alt="Professionals" title="Professionals" />
                                                <div>Professionals</div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col md="3" lg="3" sm="3" className="col-6">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Ecosystembuilders">
                                            <div className="image-icon">
                                                <img src={Ecosystem} alt="Need Fund" title="Need Fund" />
                                                <div>Ecosystem builders</div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col md="3" lg="3" sm="3" className="col-6">
                                    <Nav.Item>
                                    <Nav.Link eventKey="Marketplace">
                                        <div className="image-icon">
                                            <img src={Marketplace} alt="Marketplace" title="Marketplace" />
                                            <div>Marketplace</div>
                                        </div>
                                    </Nav.Link>
                                    </Nav.Item>
                                </Col>
                            </Row>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="mentors">
                                <section className="profit-idea-block">
                                    <Row>
                                        <p>Join <span>iPowerz</span> to provide advisory services to entrepreneurs and small businesses on the platform.</p>
                                    </Row>
                                    <MentorsForm />
                                </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Professionals">
                                <section className="profit-idea-block">
                                    <Row>
                                        <p>Join <span>iPowerz</span> to provide professional services to entrepreneurs and small businesses.</p>
                                    </Row>
                                    <ProfessionalsForm />
                                </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Ecosystembuilders">
                            <section className="profit-idea-block">
                                    <Row>
                                        <p>Business Incubators, Accelerators, Business Associations, Universities and Non Profits : Join <span>iPowerz</span> to create virtual ecosystems</p>
                                    </Row>
                                    <EcoSystemForm />
                                </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Marketplace">
                                <section className="profit-idea-block">
                                    <Row>
                                        <p>Join the <span>iPowerz</span> global marketplace for sustainable and eco-friendly businesses, investment, services and products.</p>
                                    </Row>
                                    <MarketPlaceForm />
                                </section>
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Tab.Container>
                   </Col>
                </Row>
            </Container>
        </section>
    )
}
export default MentorTabs;