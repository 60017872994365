import { Col, Row, Container } from "react-bootstrap"
import Profile from '../../Images/Profile.jpg'
export const BlogContentBlock = () => {
    return (
        <section className="blog-content-section">
            <Container>
                <section className="block-content-display">
                    <Row>
                        <Col md="8 col-lg-8 col-sm-8 col-xs-12 blog-section-block">
                           <div className="blog-title-block">
                               <span className="date">September 6, 2021</span>
                               <h4 className="blog-subtitle">Decoding Entrepreneurship</h4>
                           </div>
                           <p>For succeeding in entrepreneurship (E), there is a simple formula:</p>
                            <div><center><h4>E=IC<sup>3</sup></h4></center></div>
                            <div><h4>Initiate</h4> <p>When an idea or opportunity is identified, it will simply remain another idea or opportunity in the world unless one takes the initiative and acts on it. Initiating the processes to implement it is the first step to making it a reality.</p></div>
                            <div><h4>Connect</h4> <p>Doing it all alone is tough. Connect with potential advisors, mentors, investors, professionals, or even friends who support the initiative. They can add tremendous value in taking the idea forward and give new strength to you and your mission.</p></div>
                            <div><h4>Create</h4> <p>Let the initiative and connections help create something real, something new, something that you love. Give it the best you can.</p></div>
                            <div><h4>Change</h4> <p>Showcase your new creation proudly. Talk about it. Contribute by changing the existing markets, industries, and lives of others through your innovation.</p></div>
                            <p>Every one of the steps is a significant step in the journey. Taking the initiative, connecting, creating and effecting a change will need your full attention. But, they will reveal the marvellous entrepreneur in you.</p>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="blog-about">
                                <h5 className="blog-aboutTitle">About</h5>
                                <div id="about" className="widget">
                                <img src={Profile} alt="" title="" />
                                <div className="description">
                                    <p>Dr Tamvada believes that there will be a golden age where all people in the world will peacefully co-exist appreciating the unique value of everything and everyone. <br /></p>
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </section>
    )
}