import { Col, Container, Row, Accordion } from "react-bootstrap"

const IpowerzFastTrack = () => {
    return (
        <section className="ipowerzFastTrack mt-5 mb-5">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12">
                        <div className="aboutUs-title mt-3">iPowerz fast-tracks value creation of:</div>
                        <Accordion className="mt-5 mb-5">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>BUSINESS INCUBATORS AND ACCELERATORS</Accordion.Header>
                                <Accordion.Body>
                                <p>iPowerz takes business incubation to the next level by offering existing incubators a one stop digital platform for their start-ups to secure funding or professional skills. It allows the incubators and accelerators to streamline and scale-up their activities. Also, incubators located in rural areas can get ready access to a wealth of resources and connections that are not easy to physically access.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>INVESTORS AND NON BANKING FINANCIAL INSTITUTIONS</Accordion.Header>
                                <Accordion.Body>
                                <p>iPowerz uniquely offers trustworthy projects to potential investors for potential investment. The ecosystem is based on a blended system of checks and balances that ensures that investors can be confident that they are investing in genuine projects that are validated and have further support.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>SKILL PARKS</Accordion.Header>
                                <Accordion.Body>
                                <p>Skill parks, where individuals develop their skills to increase their employability, can benefit from the recruitment opportunities in iPowerz. Individual users from the skill parks can connect with other stakeholders in the ecosystem to partner with them or work for them. The skill parks can use iPowerz as a platform for placing their mentees and allowing them to partner with new start-ups and scale-ups.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>PROFESSIONAL SERVICE PROVIDERS</Accordion.Header>
                                <Accordion.Body>
                                <p>iPowerz connects professional service providers with potential customers like start-ups, scale-ups, investors and so on. It offers them new avenues for gaining customers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>NGOs</Accordion.Header>
                                <Accordion.Body>
                                <p>iPowerz allows NGOs supporting rural development, entrepreneurship and innovation to post projects of rural entrepreneurs and innovators to the platform. Such NGOs can accelerate their support for end users through the platform.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default IpowerzFastTrack