import { Row, Col } from 'react-bootstrap';
const WhatWeDo = () => {
    return (
        <section className="whatWeDo mt-5">
            <Row>
                    <Col md="12" lg="12" sm="12">
                        <div className="whatWeDoBlock">
                            <div className="aboutUs-title mt-3">What We do</div>
                            <p className="mt-3">We provide a global digital entrepreneurship ecosystem for Business Incubators and Accelerators,
                                Angel Investor Groups and
                                Non-banking Financial Companies, Growth Hubs, Professional
                                Service Providers, Universities, and
                                NGOs to accelerate grassroots
                                entrepreneurship and innovation.
                                We connect individuals with creative ideas, entrepreneurial aspirations, or non-profit projects with
                                investors, mentors, and professionals to jointly bring their projects to fruition.
                            </p>
                        </div>
                    </Col>
                    
                </Row>
        </section>
    )
}

export default WhatWeDo;