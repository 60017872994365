import axios from 'axios';
export const MarketApi = async Market => {
  return await new Promise((resolve,reject) => {
    const formId= 'f481ae25-346b-401f-89df-d3d777f6bd4b';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":Market.email
        },
        {
            "name": "firstname",
            "value": Market.name
        },
        {
            "name": "phone",
            "value": Market.mobileNumber
        },
        {
            "name": "website",
            "value": Market.website
        },
        {
            "name": "message",
            "value": Market.productShowCas
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": Market.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
