import axios from 'axios';

export const WantFundApi = async fundsData => {
  return await new Promise((resolve,reject) => {
    const formId= '6868c288-8dc9-45e0-9ca2-41c4fb4f301f';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    fields: [
        {
            "name":"email",
            "value":fundsData.email
        },
        {
            "name": "invest_in",
            "value": fundsData.investin
        },
        {
            "name": "product_service_name",
            "value": fundsData.pServiceName
        },
        {
            "name": "fund_amount",
            "value": fundsData.requiredFunds
        },
        {
            "name": "website",
            "value": fundsData.website
        }
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": fundsData.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
