import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
import ServicesImage from '../../Images/iPowerz-Services.png'
const OurServices = () => {
    return (
        <section className="our-services">
            <Container>
                <Row>
                    <Col md="5" lg="5" sm="5" className="self-center">
                        <div className="image mobile-visible">
                            <img src={ServicesImage} alt=""  style={{width:"100%"}} />
                        </div>
                    </Col>
                    <Col md="7" lg="7" sm="7">
                        <div className="service-content">
                            <div className="service-title-block">
                                <h4 className="service-title"><span>What we have!</span><br/>Our Services also include</h4>
                            </div>
                            <p>Software as a service applications that can create private
                                or public virtual business ecosystems with 24x7 support and instant product upgrades.
                            </p>
                           { /* <div className="serviceBtn">
                                <div className="firstServiceBlock">
                                    <Button className="btn btn-lg">User Profiles</Button>
                                    <Button className="btn btn-lg">Document Upload</Button>
                                    <Button className="btn btn-lg">Verification & Validation</Button>
                                </div>
                                <div className="firstServiceBlock">
                                    <Button className="btn btn-lg">AI and BI Analytics</Button>
                                    <Button className="btn btn-lg">Financial & Non-Financial Services</Button>
                                    <Button className="btn btn-lg">Accessing Global Markets</Button>
                                </div>
    </div> */}
                           <div className="know-more-block">
                           <a href={`${process.env.PUBLIC_URL}/`} className="know-more">Know More</a>
                           </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default OurServices;