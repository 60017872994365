import axios from 'axios';

export const NewsLetterApi = async email => {
  return await new Promise((resolve,reject) => {
    const formId= '67d2574e-4ff7-49e8-ac4f-69413609a22a';
    const portalId = '20427888'
    const config = { // important!
    headers: {
        'Content-Type': 'application/json',
    }
    }
    const path = window.location.pathname
    axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,{
    submittedAt:new Date(),    
    fields: [
        {
            "name": "email",
            "value": email
        },
    ],
    context : {
        "pageUri": `${process.env.PUBLIC_URL}`,
        "pageName": path
      },
      legalConsentOptions: {
        "consent":{
          "consentToProcess": email.GDPCompalince,
          "text":"I agree to receive other communications from iPowerz."
        }
      }
    },
    config
    ).then((res => {
        resolve(res);
      }))
      .catch(error => {
            reject(error);
        })
 })
}
