import { useEffect, useState } from 'react'
import { Navbar,Nav, Container, Row, Col, Button, Modal } from 'react-bootstrap'
import Logo from '../Images/iPowerz-Logo-01.svg'
/* import whatsapp from '../Images/whatsapp.png' */
import { CallBackForm } from './Forms/CallBackForm'
import { SupportForm } from './Forms/SupportForm'
export const NavbarMenu = (props) =>{
    /* const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const openAbout = () => {
        window.location = '#/about'
    } */
    useEffect(()=>{
        console.log(props.activeName)
    })
    return(
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href={`${process.env.PUBLIC_URL}/`}><img src={Logo} alt="logo" title="Logo" style={{width:"80%"}}/></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end self-end">
                    <Nav>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/`} className={props.activeName==='home' ? 'active':''}>Home</Nav.Link>
                        { /* <NavDropdown title="About" id="basic-nav-dropdown" className={props.activeName==='about' ? 'active':''} href="#/about"
                         show={show}
                         onMouseEnter={showDropdown} 
                         onMouseLeave={hideDropdown}
                         onClick={openAbout}>

                            <NavDropdown.Item href="#/careers">Careers</NavDropdown.Item>
                        </NavDropdown>*/ }
                        <Nav.Link href="#/about" className={props.activeName==='about' ? 'active':''}>About</Nav.Link>

                        <Nav.Link href="#/services" className={props.activeName==='services' ? 'active':''}>Services</Nav.Link>
                        
                        { /* <Nav.Link href="#">Why iPowerz</Nav.Link> */ }
                        { /* <Nav.Link href="#/investors" className={props.activeName==='investors' ? 'active':''}>Investors</Nav.Link>*/ }
                        { /* <Nav.Link href="#">Stories</Nav.Link> */ }
                        <Nav.Link href="#/media" className={props.activeName==='media' ? 'active':''}>Press & Media</Nav.Link>
                        <Nav.Link href="#/blog" className={props.activeName==='blog' ? 'active':''}>Blog</Nav.Link>
                        { /* <Nav.Link href="#/support" className={props.activeName==='support' ? 'active':''}>Support</Nav.Link>*/ }
                        <Nav.Link href="#/contact" className={props.activeName==='contact' ? 'active':''}>Contact</Nav.Link>
                        { /* <Nav.Link className="btn btn-lg mobile-enable">Request a Callback</Nav.Link>
                        <Nav.Link className="btn btn-lg mobile-enable">Schedule an Evaluation Session</Nav.Link>*/}
                        <Nav.Link className="btn btn-lg mobile-enable" href={`${process.env.REACT_APP_MY_WEB_URL}`}>Sign In</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export const TopBar = () => {
    const [show, setShow] = useState(false);
  const handleClose = () => {
      setShow(false);
  }
  const handleShow = () => setShow(true);
  const [show2, setshow2] = useState(false);
  const handleEvaluationClose = () => {
      setshow2(false)
  }
  const handleEvaluationOpen = () => setshow2(true)
  const handleSignIn = () => {
    window.open(`${process.env.REACT_APP_LOGIN_URL}`,'_blank');
}
  return (
        <>
        <section className="top-bar">
            <Container>
                <Row className="">
                    <Col md="8" lg="8">
                       <div className="topbar-flex">
                          {/*<div className="mobile-width-50"><Nav.Link eventKey="link-2"><img src={PhoneIcon}  alt="phone icon" title="phone icon" /> 1800 1234 5678</Nav.Link></div> */}
                          <div className="mobile-width-50"><Button variant="secondary" size="lg" className="btn-space-adjust" onClick={handleShow}>Request a Callback</Button></div>
                          <div className="mobile-width-50"><Button variant="secondary" size="lg" onClick={handleEvaluationOpen}>Schedule an Evaluation Session</Button></div>
                       </div>
                    </Col>
                    <Col md="4" lg="4">
                        <div className="topbar-flex justify-end desktop-top-bar">
                          {/* <div className="mobile-width-50"><Nav.Link eventKey="link-2"><img src={whatsapp}  alt="phone icon" title="phone icon" /> +44 7899 676924</Nav.Link></div> */}
                          <div className="mobile-width-50"><Button variant="secondary" size="lg" className="signInBtn" onClick={handleSignIn}>Sign In</Button></div>
                        </div>
                    </Col>
                </Row>
                <Row className="mobile-top-bar">
                    <div className="topbar-flex justify-center">
                        {/* <div className="mobile-width-50"><Nav.Link eventKey="link-2"><img src={whatsapp}  alt="phone icon" title="phone icon" /> +44 7899 676924</Nav.Link></div> */}
                    </div>
                </Row>
            </Container>
        </section>
        <Modal show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
            <Modal.Title>Request a Callback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CallBackForm />

            </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleEvaluationClose} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Schedule an Evaluation Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SupportForm />               
            </Modal.Body>
        </Modal>
        </>
    )
}