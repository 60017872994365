import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { MentorsValidation } from '../Validation/Validation';
import { ContactUsApi } from '../HubspotApis/ContactUs'
export const ContactUsForm = () => {
    const [disableSubmit,setDisableSubmit] = useState(true)
    const [contactForm,setContactForm] = useState({subject:'Subject: Account Registration Code',name:'',email:'',comments:'',GDRPolicy:false})
    const [contactSuccessMessage,setContactSuccessMessage] = useState(false)
    useEffect(()=>{
        if(contactForm.GDRPolicy){
            console.log(contactForm.GDRPolicy)
            setDisableSubmit(false)
        }
        else {
            setDisableSubmit(true)
        }
    },[contactForm.GDRPolicy])
    const ContactSubmit = e => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        alert('Please Select GDR Compilance')
        }
        else {
        const validationForm = MentorsValidation(contactForm)
        const noErrors = Object.keys(validationForm).length===0
        if(noErrors){
            ContactUsApi(contactForm).then((res)=>{
                setContactSuccessMessage(true)
                setContactForm({
                    ...contactForm,
                    subject:'Subject: Account Registration Code',
                    name:'',
                    email:'',
                    comments:'',
                    GDRPolicy: false
                })

            })
        }
        else {
            alert(validationForm)
        }
        console.log(contactForm)
    }
}
    return (
        <section className="contactUsForm">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12">
                        <div className="aboutUs-title mt-3">Get in touch</div>
                        <p className="mt-3">Please fill the form below or email us at <a href="mailto:support@ipowerz.com">support@ipowerz.com</a> for inquiries</p>
                    </Col>
                </Row>
                <Form onSubmit={ContactSubmit} noValidate>
                    <Form.Group as={Col} controlId="formGridState" className="mb-3 mt-2">
                        <Form.Label>Subject</Form.Label>
                        <Form.Select onChange={e=>setContactForm({...contactForm,subject:e.target.value})} value={contactForm.subject} className="form-control">
                            <option>Subject: Account Registration Code</option>
                            <option>Subject: Billing or account support</option>
                            <option>Subject: Business support</option>
                            <option>Subject: Login or access support</option>
                            <option>Subject: Sales support</option>
                            <option>Subject: Technical support</option>
                            <option>Subject: Other support</option>
                        </Form.Select>
                    </Form.Group>
                    <Row>
                        <Col sm={6}>
                            <Form.Group as={Col} controlId="formGridState" className="mb-3 mt-2">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" onChange={e=>setContactForm({...contactForm,name:e.target.value})} value={contactForm.name} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group as={Col} controlId="formGridState" className="mb-3 mt-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" onChange={e=>setContactForm({...contactForm,email:e.target.value})} value={contactForm.email} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group as={Col} controlId="formGridState" className="mb-3 mt-2">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder="Write comments here..." onChange={e=>setContactForm({...contactForm,comments:e.target.value})} value={contactForm.comments} />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <p className="mt-5 complaince-text-p">iPowerz is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</p>
                            <Form.Group className="" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="I agree to receive other communications from iPowerz." onChange={e=>setContactForm({...contactForm,GDRPolicy:e.target.checked})} value={contactForm.GDRPolicy} required/>
                            </Form.Group>
                            <p className="complaince-text-p">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>
                            <p className="complaince-text-p">By clicking submit below, you consent to allow iPowerz to store and process the personal information submitted above to provide you the content requested.</p>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button type="submit" className="submitButton" style={{marginTop: "30px",paddingTop: "13px",paddingBottom:"13px"}} disabled={disableSubmit} >Submit</Button>
                    </div>
                    <Alert
                        variant="success"
                        className={contactSuccessMessage ? "d-block mt-3 mb-0" : "d-none"}
                        >
                        Thanks for contacting us. our team will get back to you soon
                    </Alert>
                </Form>
            </Container>
        </section>
    )
}