import { Row, Col, Container } from 'react-bootstrap';
const MedicalFinance = () => {
    return (
        <section className="visionMission mt-5">
            <Container>
                <Row>
                    <Col md="12" lg="12">
                        <div class="aboutUs-title mt-3">Medical Finance</div>
                        <ul className="bullet-tips-list mt-5">
                            <li>
                                <p><strong>Finance any medical expenses, at a reasonable interest rate:</strong> When you have good credit, you deserve a low-interest, fixed-rate loan. You can be confident that’s what you’ll get with iPOwerz because we back our promises with our plans.</p>
                            </li>
                            <li>
                                <p><strong>Straightforward and Private:</strong> Getting a loan should be a no-hassle experience. That’s how we’ve designed it: the easy and convenient way to fund practically any medical expense.</p>
                            </li>
                            <li>
                                <p><strong>Experience the refreshingly simple iPowerz way:</strong> Check your eligibility, apply and sit back while we do the rest. </p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
export default MedicalFinance;