import { HashRouter, Route, Switch } from 'react-router-dom';
import {IndexPage} from '../src/components/Index'
import AboutUs from './components/About';
import Team from './components/Team';
import Contact from './components/Contact';
import Services from './components/Service';
import Media from './components/Media';
import Blog from './components/Blog';
import { Privacy } from './components/Privacy';
import Investors from './components/investors';
import ErrorPage from './404Error';
import Support from './components/Support';
import Careers from './components/Carrers';
import ScrollTop from './components/ScrollTop';
import { TermsConditions } from './components/TermsUse';
// import ScrollToTop from './components/ScrollTop';

export const AppLoader = () => {
    return(
        <HashRouter basename={process.env.PUBLIC_URL} hashType="slash">
            <ScrollTop>
            <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route path="/about" component={AboutUs} />
            <Route path="/team" component={Team} />
            <Route path="/contact" component={Contact} />
            <Route path="/services" component={Services} />
            <Route path="/media" component={Media} />
            <Route path="/blog" component={Blog} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/investors" component={Investors} />
            <Route path="/careers" component={Careers} />
            <Route path="/support" component={Support} />
            <Route path="/terms" component={TermsConditions} />
            <Route path='*' exact={true} component={ErrorPage} />
            </Switch>
            </ScrollTop>
        </HashRouter>
    )
}