import { NavbarMenu, TopBar } from './navbar'
import '../css/AboutUs.css'
import NewsLetter from "./Home/NewsLetter"
import Footer from "./Home/Footer"
import React, { useEffect, useState } from 'react'
import { CareerTiltle } from './Careers/CareerTiltle'
import CarrerContentBlock from './Careers/CarrerContentBlock'
import ScrollTop from 'react-scrolltop-button';
const Careers = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('fixed-top')
    } else {
        setnavbarPosition('')
    }
    }
    return(
        <>
        <section className={navBarPosition}>
            <TopBar />
            <NavbarMenu />
        </section>
        <section className="aboutUs-bg">
            <section className="aboutUs">
                <CareerTiltle />
                <CarrerContentBlock />
            </section>
        </section>
        <NewsLetter />
        <Footer />
        <ScrollTop 
        text="🠙"
        style={{ backgroundColor: "#f7560f", border: 'none', paddingTop: "0px" ,paddingBottom: "0px", color: "#ffffff", fontSize: "32px" }}
        className="scroll-your-role"
        />
        </>
    )
}
export default Careers;