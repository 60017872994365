import { Container } from 'react-bootstrap';
const InvestorHeader = () => {
    return(
        <section className="Team-title">
            <Container>
                <div className="about-powerz-content">
                    <div className="about-title-center">
                        <h4 className="about-powerz-title">Investors</h4>
                    </div>
                </div>
            </Container>
        </section>
    )
}
export default InvestorHeader;