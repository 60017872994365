import { Col, Container, Row, Accordion } from "react-bootstrap"
import BusinessService from '../../Images/services 01- BusinessPlan.png'
import CollaborativeOpenGroups from '../../Images/services 02- Collaborative Open Group.png'
import Funding from '../../Images/services 03-Funding.png'
import StartUpIdeas from '../../Images/services 04-Startup Funding Ideas.png'
import GrowthStargey from '../../Images/services 05-Growth Strategy.png'
import CommunityIdeas from '../../Images/services 06-Support for Community Ideas.png'
import NonProfitIdeas from '../../Images/services 07-Non Profit Ideas.png'
import MarketPlace from '../../Images/services 08-Market Place.png'
import TalentAcaqusition from '../../Images/services 09-Talent Aquisition.png'
// import CollaborativePlatform from '../../Images/services 10-Collaborative Platform.png'
import BackgroundVerification from '../../Images/services 11-Background Verification.png'
import EnterpriuesalPartnership from '../../Images/services 12-Enterpreneurial Partnership.png'
import ProfessionalServices from '../../Images/services 13-Professional Services.png'
// import PrivateEquipment from '../../Images/services 14-Private Equity Fullfillment.png'
// import Donations from '../../Images/services 15-Donations.png'
// import SAwarness from '../../Images/services 16-Awareness Creation.png'
export const OurServicesBlock = () => {
    return(
        <section className="our-services-block">
            <Container>
                <div className="aboutUs-title mt-3 mb-3">Ways to work with iPowerz</div>
                <Accordion defaultActiveKey="0" className='service-accordians mt-5'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={BusinessService} alt="Business Planning" title="Business Planning" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">The End-to-End platform</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>iPowerz provides a safe and secure digital platform for entrepreneurs, investors, and professionals. The first of its kind globally, that supports microentrepreneurs and small businesses by matching them with investors, donors, mentors, professionals, service providers and consumers to develop new business opportunities using AI embedded business intelligence.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                        { /*<Accordion.Body></Accordion.Body>*/ }
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={EnterpriuesalPartnership} alt="iPowerz Market Place" title="iPowerz Market Place" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">B2B platform</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                    <p>Any organization that supports business growth and serves a group of businesses can create a virtual ecosystem to facilitate collaboration and accelerate business growth. We take business incubation to the next level by offering existing incubators a one stop digital platform for their start-ups to secure funding or professional skills. We allow the incubators and accelerators to streamline and scale-up their activities. Also, incubators located in rural areas can get ready access to a wealth of resources and connections that are not easy to physically access.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                        <div className="service-icon"><img src={CollaborativeOpenGroups} alt="Collaborative Open Groups" title="Collaborative Open Groups" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                           <div className="service-icon-title">Collaborative Platform</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>
                                       Our collaborative open groups engage all the stake holders using our secured and private in-mails, chats, events, conferences etc. Our dashboard will alert the status of the submissions, granting of funds, email & message alerts and many more useful tacking & monitoring features. You can now convert the business plan data into smart plans using our strategic & personalized templates.
                                   </p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                           <div className="service-icon"><img src={Funding} alt="Funding" title="Funding" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                          <div className="service-icon-title">Financial & Non-Financial Services</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>
                                       We uniquely offer trustworthy projects to potential investors for potential investment. The ecosystem is based on a blended system of checks and balances that ensures that investors can be confident that they are investing in genuine projects that are validated and have further support. We offer financial Services like Crowd Funding and Debt/Equity Options as well as non-financial services like connecting with Mentors and Professionals.
                                   </p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={StartUpIdeas} alt="StartUpIdeas" title="StartUpIdeas" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                           <div className="service-icon-title">Start-Up Ideas</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>
                                       Entrepreneurs create jobs, create prosperity and economic growth through start-ups. Our platform introduces entrepreneurs to potential investors and financial institutions for successfully raising funds. iPowerz also empowers entrepreneurs by providing them with tools to communicate their ideas to investors by creating smart and optimal business plans.
                                   </p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                             <div className="service-icon"><img src={GrowthStargey} alt="Growth Strategy for Existing Businesses" title="Growth Strategy for Existing Businesses" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">Growth Strategy for Existing Businesses</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>
                                       Not only supporting for newer ideas and also any existing businesses growth plans are welcome to make use of iPowerz tools, aids and support features in realizing the dreams and visions of the seasoned as well as grassroot entrepreneurs alike.
                                   </p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={CommunityIdeas} alt="Support for Community Ideas" title="Support for Community Ideas" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">Support for Community Ideas</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>iPowerz enables you to post ideas that can help communities. If you identify a problem that people in your community are facing and have an idea about resolving it, iPowerz will enable you to post your idea and get support from around the world to implement it.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                           <div className="service-icon"><img src={NonProfitIdeas} alt="Support for Non-Profit Ideas" title="Support for Non-Profit Ideas" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">Support for Non-Profit Ideas</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>iPowerz enables you to post non-profit ideas and seek financial support for implementing them. These are projects that improve the life of other people and are not driven by profit-making motives. For example, if you are an NGO that requires financial support for one of your non-profit projects, you can propose it on iPowerz and seek funding. We will help with your plans to make them robust. We demonstrate trust to all our social stakeholders by providing features to track and get updates on project delivery and implementation.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={MarketPlace} alt="iPowerz Market Place" title="iPowerz Market Place" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">iPowerz Market Place</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>We can offer many Social Media, ERP, ECOM, CRM etc B2B services and introduce the end products to different markets across the world.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                        <div className="service-icon"><img src={TalentAcaqusition} alt="iPowerz Market Place" title="iPowerz Market Place" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                            <div className="service-icon-title">Talent Acquisition</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>Skill parks, where individuals develop their skills to increase their employability, can benefit from the recruitment opportunities in iPowerz. Individual users from the skill parks can connect with other stakeholders in the ecosystem to partner with them or work for them. The skill parks can use iPowerz as a platform for placing their mentees and allowing them to partner with new start-ups and scale-ups.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                            <div className="service-icon"><img src={BackgroundVerification} alt="iPowerz Market Place" title="iPowerz Market Place" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                           <div className="service-icon-title">Background Verification</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>Our Verification & Validation services provides many features to securely assess the business data like user forms, documents, images, videos, etc. and perform required background checks.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header className="no-arrow-scrollButton">
                            <Row>
                                <Col sm={3} className="self-center">
                                    <Row>
                                        <Col xs={4} className="self-center">
                                           <div className="service-icon"><img src={ProfessionalServices} alt="iPowerz Market Place" title="iPowerz Market Place" /></div>
                                        </Col>
                                        <Col xs={8} className="self-center">
                                           <div className="service-icon-title">Professional Services</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className="self-center services-block-btn-p">
                                   <p>iPowerz connects professional service providers with potential customers like start-ups, scale-ups, investors and so on. It offers them new avenues for gaining customers.</p>
                                </Col>
                            </Row>
                        </Accordion.Header>
                    </Accordion.Item>
                </Accordion>   
            </Container>
        </section>
    )
}