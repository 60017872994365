import { useEffect, useState } from 'react'
import { Row, Col, Button, Form, Alert } from 'react-bootstrap'
import { MentorsValidation } from '../Validation/Validation'
import { RequestApi } from '../HubspotApis/RequestCallBack'
export const CallBackForm = () => {
  const [evaluation,setevaluation] = useState({name:'',email:'',mobileNumber:'',visitPurpose:'',GDPCompalince:false})
  useEffect(()=>{
      if(evaluation.GDPCompalince){
            setDisableSubmit(false)
          }
          else {
            setDisableSubmit(true)
          }

  },[evaluation.GDPCompalince])
  const [disableSubmit,setDisableSubmit] = useState(true)
 const [successMessage,setSuccessMessage]=useState('');
  const evaluationSubmission = e =>{
    e.preventDefault()
    const ValidationForm = MentorsValidation(evaluation)
    const noErrors = Object.keys(ValidationForm).length===0
    if(noErrors){
        RequestApi(evaluation).then((res)=>{
          setSuccessMessage('Thanks for contacting us. Our team will get back to you soon.')
          setevaluation({
              ...evaluation,
              name: '',
              email: '',
              mobileNumber: '',
              visitPurpose: ''
          })

      }).catch(error=>{
        console.log(error)
    })
    }
    else {
        alert(ValidationForm)
    }
} 
    return (
        <>
        <Form onSubmit={evaluationSubmission} noValidate>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" onChange={e=>setevaluation({...evaluation,name:e.target.value})} value={evaluation.name}/>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control type="number" placeholder="Enter Mobile Number" onChange={e=>setevaluation({...evaluation,mobileNumber:e.target.value})} value={evaluation.mobileNumber} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email Address" onChange={e=>setevaluation({...evaluation,email:e.target.value})} value={evaluation.email} />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                        <Form.Label>Purpose</Form.Label>
                        <Form.Control as="textarea" rows="3" placeholder="Purpose" onChange={e=>setevaluation({...evaluation,visitPurpose:e.target.value})} value={evaluation.visitPurpose} />
                    </Form.Group>
                </Col>
            </Row>
            <Col sm={12}>
                <span className="complaince-text-p">iPowerz is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</span>
                <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="I agree to receive other communications from iPowerz." onChange={e=>setevaluation({...evaluation,GDPCompalince:e.target.checked})} value={evaluation.GDPCompalince} required/>
                </Form.Group>
                <span className="complaince-text-p">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</span>
                <span className="complaince-text-p">By clicking submit below, you consent to allow iPowerz to store and process the personal information submitted above to provide you the content requested.</span>
            </Col>
            <div className="text-center">
                <Button type="submit" className="submitButton" style={{marginTop: "30px",paddingTop: "13px",paddingBottom:"13px"}} disabled={disableSubmit}>Submit</Button>
            </div>
        </Form>
        <Alert
            variant="success"
            className={successMessage ? "d-block mt-3 mb-0" : "d-none"}
            >
            Thanks for contacting us. Our team will get back to you soon.
        </Alert>
        </>
    )
}